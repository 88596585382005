<template>
<div>
    <div class="dashboard-widget-tabs dashboard-widget-pages p-0 ">
        <div class="dashboard-widget-tabs-content mb-0" style="padding: 15px">
            <h2> <i class="uil uil-chart-line"></i> مشاهده تحلیل </h2>
            <p> مشاهده تحلیل شماره {{ this.$route.params.id }} با عنوان {{ technical.title}} </p>
            <div class="dashboard-widget-h-btns center-row">
                <slot v-if="$store.state.user && technical.user.username != $store.state.user.username">
                    <router-link v-if="!widgetLoad" :to="{ name: 'ProfileOthers' , params: {username : technical.user.username} }" class="m-left m-top tgju-btn-badge tgju-btn-light" v-tooltip="'کاربر تحلیل کننده'">
                        <i class="uil uil-user"></i>
                        {{ technical.user.firstname }} {{ technical.user.lastname }}
                    </router-link>
                </slot>
                <slot v-else>
                    <div v-if="!widgetLoad" class="m-left m-top tgju-btn-badge tgju-btn-light" v-tooltip="'کاربر تحلیل کننده'">
                        <i class="uil uil-user"></i>
                        {{ technical.user.firstname }} {{ technical.user.lastname }}
                    </div>
                </slot>
                <div v-if="!widgetLoad" class="m-left m-top tgju-btn-badge tgju-btn-light" v-tooltip="'تاریخ ایجاد'">
                    <i class="uil uil-clock-nine"></i>
                    <span> {{ toRelative(technical.created_at) }}</span>
                </div>
                <div v-if="!widgetLoad" class="m-left m-top tgju-btn-badge tgju-btn-light" v-tooltip="'لایک'">
                    <i class="uil uil-thumbs-up pointer" aria-hidden="true" style="color:#e63946;" v-if="technical.liked" @click="likePost(technical.technical.id)"></i>
                    <i class="uil uil-thumbs-up pointer" aria-hidden="true" v-else @click="likePost(technical.technical.id)"></i>
                    <span> {{ technical.likes }}</span>
                </div>
                <div v-if="!widgetLoad" @click.prevent="back()" class="m-left m-top tgju-btn tgju-btn-light" v-tooltip="'بازگشت'">
                    <i class="uil uil-angle-left-b"></i>
                    <span class="hide-m-min">بازگشت </span>
                </div>
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>

            </div>
        </div>
    </div>

    <div class="row tgju-widgets-row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            <div class="tgju-widget light tv-iframe-page-p">
                <slot v-if="widgetLoad">
                    <div class="tgju-widget-content full-padding">
                        <div class="text-center p-2">
                            <div class="widget-loading"></div>
                        </div>
                    </div>
                </slot>
                <slot v-else>
                    <ViewTechnicalComponent :data="data"></ViewTechnicalComponent>
                </slot>
            </div>
        </div>
    </div>
    <div class="row tgju-widgets-row technical-m-top">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            <div class="tgju-widget light">
                <span class="technical-body-title">
                    توضیحات تحلیل {{ technical.title }}
                </span>
                <div class="technical-body">
                    <slot v-if="$helpers.isJson(technical.attachments) && technical.attachments && $helpers.json_pars(technical.attachments)[1]">
                        <a :href="$helpers.api_url() + $helpers.json_pars(technical.attachments)[1].name" target="_blank">
                            <img :src="$helpers.api_url() + $helpers.json_pars(technical.attachments)[1].name" style="width:100%;height:auto;margin-bottom:20px;max-width:350px">
                        </a>
                    </slot>
                    {{ technical.content }}
                </div>
            </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-6 mt-1200">
            <Post type="widget" auth="hide" username="everybody" sendbox="enabled" :subject="subject"></Post>
        </div>
    </div>
</div>
</template>

<script>
// این ویو برای مشاهده جزئیات یک تحلیل در پنل کاربری مورد استفاده قرار میگیرد 
import ViewTechnicalComponent from '@/components/Technical/ViewTechnical.vue';
import Post from '@/components/TgjuPosts/Post.vue';
import moment from "moment-jalaali";

export default {
    name: 'ViewTechnical',
    components: {
        ViewTechnicalComponent,
        Post,
    },
    data: function () {
        return {
            data: {},
            technical: {
                title: undefined,
                user: {
                    firstname: undefined,
                    lastname: undefined,
                    username: undefined,
                }
            },
            subject: 'dashboard-technical-' + this.$route.params.id,
            widgetLoad: true
        }
    },
    mounted() {
        // در صورتی که کارب لاگین کرده بود یک سری اکشن ها را کال کن
        if (this.$helpers.userSync()) {
            this.$store.commit('setAccessToken', localStorage.getItem('access_token'));
            this.$store.dispatch('setUserData', { forced: true });
            // this.hit(this.$route.query.tech_id);
        }

        this.data = {
            id: this.$route.params.id,
        }
        this.getTechnical(this.$route.params.id);

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        // تبدیل تاریخ به مقادیر مناسب
        toRelative(date) {
            return moment(date).fromNow();
        },
        // ارسال درخواست به وبسرویس جهت دریافت اطلاعات تحلیل
        getTechnical(id) {
            this.$helpers.makeRequest('GET', `/technical/find/${id}`).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.data.status == false) {
                    this.$router.push({ name: 'Profile' });
                    return;
                }

                this.technical = api_response.data.response.technical;
                this.widgetLoad = false;
            });
        },
        // ارسال درخواست ای پی آی جهت افزایش بازدید
        hit(id) {
            this.$helpers.makeRequest('POST', `/technical/hit/${id}`).then(api_response => {});
        },
        // ارسال درخواست جهت لایک کردن تحلیل
        likePost(id) {
            let api_data = {
                action: this.technical.liked ? 'unlike' : 'like'
            };

            this.$helpers.makeRequest('GET', '/technical/like/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.technical.liked = !this.technical.liked;
                    this.technical.likes = api_response.data.response.likes;
                }
            });
        },
        // دکمه بازگشت
        back() {
            this.$router.back()
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Technical.vue',
                title: 'تحلیل‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
